import Service from "./Service";
const menu = "Account/";

export default {
    login(request) {
        localStorage.clear();

        return Service.post(menu + "login", request , {
            params: {},
        });
    },

    refreshToken(request) {
        return Service.post(menu + "refresh-token", request , {
            params: {},
        });
    },

    changePassword(request) {
        return Service.post("SecUser/" + "change-password", request, {
            params: {},
        });
    },


    createAccount(request) {
        return Service.post(menu + "create-account", request, {
            params: { },
        });
    },

    recoveryPassword(request) {
        return Service.post(menu + "recovery-password", request, {
            params: { },
        });
    },

    
    authorizeAccount(request) {
        return Service.post(menu + "authorize-account", request, {
            params: { },
        });
    },

    validateChangePassword(request) {
        return Service.post(menu + "validate-change-password", request, {
            params: { },
        });
    },

    CheckPasswordChange(email) {
        return Service.get("SecUser/" + "CheckPasswordChange", {
            params: {email},
        });
    },

    RegisterPasswordChange(request) {
        return Service.post("SecUser/" + "RegisterPasswordChange",request, {
            params: {},
        });
    },
};