// Importaciones globales
window.$ = require("jquery");
window.jQuery = require("jquery");
import push from "push.js";
import Vue from 'vue';
import App from './Core.vue';
import router from './router';
import ArgonDashboard from "./argon-dashboard";
import BootstrapVue from "bootstrap-vue";
import moment from "moment";
import VueMoment from "vue-moment";
import VueMask from "vue-the-mask";
import IdleVue from "idle-vue";
import _ from "lodash";
import CKEditor from 'ckeditor4-vue';
import rate from "vue-rate";
import store from "./store/";
import vuetify from "./plugins/vuetify.js";
import fun from "./helpers/fun";
import constapp from "./helpers/const-es";
import base from "./plugins/base";
import axios from "./services/Service";
import './registerServiceWorker';
import vcNotification from './components/Utils/vcNotification.vue';

// Configuración de plugins y componentes globales
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 600000, // 10 minutos,
  startAtIdle: false
});
const eventBus = new Vue();
Vue.prototype.$eventBus = eventBus;
const eventErr = new Vue();
Vue.prototype.$eventErr = eventErr;
Vue.use(_);
Vue.use(VueMask);
Vue.use(BootstrapVue);
Vue.use(CKEditor);
Vue.use(rate);

Vue.prototype.moment = moment;
Vue.prototype.$const = constapp;
Vue.prototype.$fun = fun;
Vue.prototype.$push = push;
Vue.prototype.$http = axios;

// Función para mostrar notificaciones
function showNotification(error) {
  const ComponenteError = Vue.extend(vcNotification);
  const errorComponent = new ComponenteError({
    propsData: {
      message: error
    }
  }).$mount();
  document.body.appendChild(errorComponent.$el);
}

// Configuración de interceptores de axios
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token != null) {
      config.headers.common["Authorization"] = "Bearer " + token;
      config.headers.common['CpyID'] = Number(fun.getCompanyID());
      config.headers.common['UsrID'] = Number(fun.getUserID());
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    console.log("ERRORRRRR--> ", error);
    if (error.response == null) {
      fun.alertFull('Error de Conexión', 'error');
      return Promise.reject(error);
    }  
    var errors = error.response.data.errors;
    var messageResponse = '[Error]';
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          messageResponse = errors;
          showNotification(messageResponse);
          if(messageResponse.message) {
            fun.alertFull(messageResponse.message,"error");
          }
          break;
        case 401:
          fun.alertFull("Solicitud denegada, Usuario no autenticado.", "error");
          Vue.prototype.$eventBus.$emit('sessionExpired');
          localStorage.clear();
          router.push("/micuenta");
          break;
        case 403:
          fun.alert("Solicitud denegada.", "warning");
          break;
        case 404:
          if (localStorage.getItem("token") != null) {
            fun.alert("El servicio solicitado no existe.");
          }
          break;
        case 500:
          if (localStorage.getItem("token") != null) {
            fun.alert(error.response.data.ExceptionMessage, "error");
          } else {
            fun.alert("[Connection Failed]", "error");
          }
          localStorage.setItem("isIdle", false);
          localStorage.clear();
          router.push("/micuenta");
          break;
        case 502:
          if (localStorage.getItem("token") != null) fun.alert(messageResponse, "error");
          break;
      }
      return Promise.reject(error);
    }
  }
);

// Configuración de Moment.js
require("moment/locale/es");
moment.locale("es");
Vue.use(VueMoment, { moment });

// Directivas y configuraciones adicionales
Vue.directive("focus", {
  inserted: function(el) {
    el.firstChild.firstChild.childNodes[1].childNodes[1].focus();
  },
});

Vue.config.productionTip = true;
Vue.config.silent = true;
Vue.config.devtools = false;
Vue.config.errorHandler = function (err, vm, info) {
  Vue.prototype.$eventErr.$emit('errorGlobal', info);
};

// Creación y montaje de la instancia de Vue
new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

// Configuración del Service Worker para recargar en caso de actualización
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    window.location.reload();
  });
}
